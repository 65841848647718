@keyframes aurora {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.auroraBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; // Ensure it's behind other content
  opacity: 0.15; // Keep it subtle
  background: linear-gradient(
    -45deg,
    #ED1C24,
    #8820B9,
    #4845D3,
    #23d5ab
  );
  background-size: 400% 400%;
  animation: aurora 10s ease infinite; // Adjust speed as needed
} 